<template>
    <Toast />
    <div class='login-body'>
        <div class='login-wrapper'>
            <div class='login-panel'>
                <img src='layout/images/logo-dark.svg' class='logo' alt='ideabox-it-logo' />
                <form @submit.prevent='handleSubmit(!v$.$invalid)' class='p-fluid'>
                    <div class='login-form'>
                        <h2>Login</h2>
                        <div class='field'>
                            <div class='p-float-label p-input-icon-right'>
                                <i class='pi pi-envelope' />
                                <InputText :class="{'p-invalid':v$.formData.email.$invalid && submitted}" id='email'
                                           v-model='formData.email' placeholder='Email' />
                            </div>
                            <small
                                v-if='(v$.formData.email.email.$invalid && submitted) || v$.formData.email.$pending.$response'
                                class='p-error'>{{ v$.formData.email.email.$message.replace('Value', 'Email') }}</small>
                            <small
                                v-else-if='(v$.formData.email.required.$invalid && submitted) || v$.formData.email.$pending.$response'
                                class='p-error'>{{ v$.formData.email.required.$message.replace('Value', 'Email')
                                }}</small>
                        </div>

                        <div class='field'>
                            <div class='p-float-label'>
                                <Password :class="{'p-invalid':v$.formData.password.$invalid && submitted}"
                                          placeholder='Password' v-model='formData.password' toggleMask />
                            </div>
                            <small
                                v-if='(v$.formData.password.$invalid && submitted) || v$.formData.password.$pending.$response'
                                class='p-error'>{{ v$.formData.password.required.$message.replace('Value', 'Password')
                                }}</small>
                        </div>

                        <Button label='Login' type='submit'></Button>
                    </div>
                </form>

                <p>Esqueceu sua senha ?
                    <router-link to='/'>Clique aqui</router-link>
                    para recuperar sua conta.
                </p>
            </div>
            <div class='login-image'>
                <div class='login-image-content'>
                    <h1>IBI - Finance</h1>
                    <h1>Nunca foi tão fácil</h1>
                    <h1>administrar suas contas</h1>
                    <h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';


export default {
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
        let token = localStorage.getItem('token');
        if (token) {
            this.$router.push('/dashboard');
        }
    },
    data() {
        return {
            submitted: false,
            formData: {
                email: '',
                password: '',
            },
        };
    },
    validations: {
        formData: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        },
    },
    methods: {
        async handleSubmit(isFormvalid) {
            if (!isFormvalid) {
                this.submitted = true;
                return;
            }
            try {
                await AuthService.login(this.formData);
                this.$router.push('/dashboard');
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: error.response.data.message,
                    life: 3000,
                });
            } // credentials didn't match
        },
    },
};
</script>
