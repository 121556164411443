import axios from 'axios';
import store from '../store/index';

const URL = process.env.VUE_APP_API_URL;

let token = localStorage.getItem('token') || '';

const api = axios.create({
    baseURL: URL,
});

api.interceptors.request.use(async (config) => {

    let token = localStorage.getItem('token') || '';
    token = token ?? store.state.auth.token;
    if (token) {
        config.headers.authorization = token;
    }

    return config;
});

api.interceptors.response.use(function(response) {
    // Do something with response data
    if (response.data) {
        if (response.data.token) {
            token = 'Bearer ' + response.data.token;
            store.commit('auth/setToken', token);
            store.commit('auth/setUser', response.data.user);
        }
    }

    return response;
}, function(error) {
    // Do something with response error
    if (error.response) {
        if (401 == error.response.status) {
            store.commit('auth/removeUser');
            store.commit('auth/removeToken');
        }
    }
    return Promise.reject(error);
});

export default api;