export const auth = {
    namespaced: true,
    state: {
        token: '',
        user: {}
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        removeUser(state) {
            state.user = null;
            localStorage.removeItem('user');
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        removeToken(state) {
            state.token = null;
            localStorage.removeItem('token');
        },
    }
};