<template>
    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <div class="layout-content-wrapper">
            <AppTopBar :topbarNotificationMenuActive="topbarNotificationMenuActive" :topbarUserMenuActive="topbarUserMenuActive"
                       :layoutMode="layoutMode" :menu="menu" :menuActive="menuActive" :staticMenuMobileActive='staticMenuMobileActive'
                       @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"
                       @menu-button-click="onMenuButtonClick" @search-click="toggleSearch" @topbar-notification="onTopbarNotificationMenuButtonClick"
                       @topbar-user-menu="onTopbarUserMenuButtonClick" @right-menu-click="onRightMenuButtonClick" @right-menubutton-click="onRightMenuButtonClick"></AppTopBar>

            <div class="layout-content">
                <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
                    <template v-if="$route.meta.breadcrumb">
                        <span>{{$route.meta.breadcrumb[0].label}}</span>
                    </template>
                </div>

                <router-view />
            </div>
            <AppFooter/>
        </div>


        <AppConfig v-model:configActive="configActive" v-model:layoutMode="layoutMode" v-model:menuTheme="menuTheme" v-model:colorScheme="colorScheme" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig>

        <AppSearch :searchActive="searchActive" @search-click="onSearchClick" @search-hide="onSearchHide"/>
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppSearch from "./AppSearch";
export default {
    data() {
        return {
            menuActive: false,
            layoutMode: "static",
            colorScheme: "light",
            menuTheme: "layout-sidebar-darkgray",
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarUserMenuActive: false,
            notificationMenuClick: false,
            topbarNotificationMenuActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    items: [
                        {label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard"},
                    ],
                },
                { separator: true },
                {
                    label: "Transaçōes",
                    items: [
                        { label: 'Despesas', icon: 'pi pi-fw pi-arrow-down', to: '/expenses' },
                        { label: 'Receita', icon: 'pi pi-fw pi-arrow-up', to: '/incomes' },
                        { label: 'Extrato', icon: 'pi pi-fw pi-wallet', to: '/transactions' },
                        { label: 'Categorias', icon: 'pi pi-fw pi-list', to: '/categories' },
                    ]
                },
                { separator: true },
                {
                    label: 'Bank',
                    items: [
                        { label: 'Banco ', icon: 'pi pi-fw pi-building', to: '/banks' },
                    ],
                },
                // {
                //     label: "UI Kit", icon: "pi pi-fw pi-star-fill",
                //     items: [
                //         {label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout"},
                //         {label: "Input", icon: "pi pi-fw pi-check-square", to: "/input"},
                //         {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
                //         {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate"},
                //         {label: "Button", icon: "pi pi-fw pi-mobile", to: "/button", class: 'rotated-icon'},
                //         {label: "Table", icon: "pi pi-fw pi-table", to: "/table"},
                //         {label: "List", icon: "pi pi-fw pi-list", to: "/list"},
                //         {label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree"},
                //         {label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel"},
                //         {label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay"},
                //         {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
                //         {label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu"},
                //         {label: "Message", icon: "pi pi-fw pi-comment", to: "/messages"},
                //         {label: "File", icon: "pi pi-fw pi-file", to: "/file"},
                //         {label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart"},
                //         {label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc"},
                //     ],
                // },
                // { separator: true },
                // {
                //     label: "Utilities", icon: "pi pi-fw pi-compass",
                //     items: [
                //         {label: "Display", icon:"pi pi-fw pi-desktop", to:"/display"},
                //         {label: "Elevation", icon:"pi pi-fw pi-external-link", to:"/elevation"},
                //         {label: "Flexbox", icon:"pi pi-fw pi-directions", to:"/flexbox"},
                //         {label: "Icons", icon:"pi pi-fw pi-prime", to:"/icons"},
                //         {label: "Text", icon:"pi pi-fw pi-pencil", to:"/text"},
                //         {label: "Widgets", icon:"pi pi-fw pi-star", to:"/widgets"},
                //         {label: "Grid System", icon:"pi pi-fw pi-th-large", to:"/grid"},
                //         {label: "Spacing", icon:"pi pi-fw pi-arrow-right", to:"/spacing"},
                //         {label: "Typography", icon:"pi pi-fw pi-align-center", to:"/typography"},
                //     ],
                // },
                // { separator: true },
                // {
                //     label: "UI Blocks", icon: "pi pi-building",
                //     items: [
                //         {label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW"},
                //         {label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-vue"}
                //     ]
                // },
                // { separator: true },
                // {
                //     label: "Pages", icon: "pi pi-fw pi-briefcase",
                //     items: [
                //         {label: "Crud", icon: "pi pi-fw pi-pencil", to: "/crud"},
                //         {label: "Calendar", icon: "pi pi-fw pi-calendar-plus", to: "/calendar"},
                //         {label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
                //         {label: "Landing", icon: "pi pi-fw pi-user-plus", url: "pages/landing.html", target: "_blank"},
                //         {label: "Login", icon: "pi pi-fw pi-sign-in", to: "/login"},
                //         {label: "Invoice", icon: "pi pi-fw pi-dollar", to: "/invoice"},
                //         {label: "Help", icon: "pi pi-fw pi-question-circle", to: "/help"},
                //         {label: "Error", icon: "pi pi-fw pi-times-circle", to: "/error"},
                //         {label: "Not Found", icon: "pi pi-fw pi-exclamation-circle", to: "/notfound"},
                //         {label: "Access Denied", icon: "pi pi-fw pi-lock", to: "/access"},
                //         {label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty"}
                //     ],
                // },
                // { separator: true },
                // {
                //     label: "Hierarchy", icon: "pi pi-fw pi-align-left",
                //     items: [
                //         {
                //             label: "Submenu 1",icon: "pi pi-fw pi-align-left",
                //             items: [
                //                 {
                //                     label: "Submenu 1.1", icon: "pi pi-fw pi-align-left",
                //                     items: [
                //                         {label: "Submenu 1.1.1", icon: "pi pi-fw pi-align-left"},
                //                         {label: "Submenu 1.1.2", icon: "pi pi-fw pi-align-left"},
                //                         {label: "Submenu 1.1.3", icon: "pi pi-fw pi-align-left"},
                //                     ],
                //                 },
                //                 {
                //                     label: "Submenu 1.2", icon: "pi pi-fw pi-align-left",
                //                     items: [
                //                         {label: "Submenu 1.2.1", icon: "pi pi-fw pi-align-left"},
                //                     ],
                //                 },
                //             ],
                //         },
                //         {
                //             label: "Submenu 2", icon: "pi pi-fw pi-align-left",
                //             items: [
                //                 {
                //                     label: "Submenu 2.1", icon: "pi pi-fw pi-align-left",
                //                     items: [
                //                         {label: "Submenu 2.1.1", icon: "pi pi-fw pi-align-left"},
                //                         {label: "Submenu 2.1.2", icon: "pi pi-fw pi-align-left"},
                //                     ],
                //                 },
                //                 {
                //                     label: "Submenu 2.2", icon: "pi pi-fw pi-align-left",
                //                     items: [
                //                         {label: "Submenu 2.2.1", icon: "pi pi-fw pi-align-left"},
                //                     ],
                //                 },
                //             ],
                //         },
                //     ],
                // },
                // { separator: true },
                // {
                //     label: "Start", icon: "pi pi-fw pi-download",
                //     items: [
                //         {label: "Buy Now", icon: "pi pi-fw pi-shopping-cart", command: () => window.open("https://www.primefaces.org/store", "_blank")},
                //         {label: "Documentation", icon: "pi pi-fw pi-info-circle", to: "/documentation"},
                //     ],
                // },
            ],
        };
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-slim": this.layoutMode === "slim",
                    'layout-horizontal': this.layoutMode === 'horizontal',
                    "layout-sidebar-dim": this.colorScheme === "dim",
                    "layout-sidebar-dark": this.colorScheme === "dark",
                    "layout-overlay-active": this.overlayMenuActive,
                    "layout-mobile-active": this.staticMenuMobileActive,
                    "layout-static-inactive": this.staticMenuDesktopInactive && this.layoutMode === "static",
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                },
                this.colorScheme === "light" ? this.menuTheme : "",
            ];
        },
    },
    components: {
        AppTopBar,
        AppFooter,
        AppConfig,
        AppSearch
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.userMenuClick) {
                this.topbarUserMenuActive = false;
            }

            if (!this.notificationMenuClick) {
                this.topbarNotificationMenuActive = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim() || this.isHorizontal()) {
					EventBus.emit('reset-active-index');
					this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.rightMenuClick = false;
            this.notificationMenuClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarNotificationMenuActive = false;
            this.rightMenuActive = false;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
                this.menuActive = false;
            }
        },
		onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onTopbarUserMenuButtonClick(event) {
            this.userMenuClick = true;
            this.topbarUserMenuActive = !this.topbarUserMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.notificationMenuClick = true;
            this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onRightMenuClick() {
			this.rightMenuClick = true;
		},
        onRightMenuButtonClick(event) {
			this.rightMenuClick = true;
			this.rightMenuActive = !this.rightMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === "slim";
        },
        isHorizontal() {
            return this.layoutMode === "horizontal";
        },
        isOverlay() {
            return this.layoutMode === "overlay";
        },
        isDesktop() {
            return window.innerWidth > 1091;
        }
    },
};
</script>

<style lang="scss">
@import "App.scss";
</style>
