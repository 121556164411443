<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="layout/images/logo-dark.svg" alt="diamond-layout" />
            <span class="app-name">IBI - Finance</span>
        </div>
        <span class="copyright">&#169; IDEABOX IT株式会社 - {{ year }}</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    data() {
        return {
            year: new Date().getFullYear()
        };
    }
};
</script>